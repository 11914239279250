<template>
  <div>
    <div class="d-flex">
      <cart-product-item-image
        :url="url"
        :image-url="imageUrl"
        :individualisation="!!individualisation"
      ></cart-product-item-image>
      <div class="pl-3 d-flex flex-column w-full">
        <div class="d-flex justify-space-between">
          <div>
            <nuxt-link
              class="
                text-h6
                d-block
                text-sm-h5 text-decoration-none
                grey-darken-4--text
              "
              :to="localePath(url)"
              >{{ title }}</nuxt-link
            >
            <div class="text-body-4 mt-1">{{ optionName }}</div>
            <div class="text-body-4 mt-1">
              {{ $t('order.itemQuantity', { itemQuantity: item.quantity }) }}
            </div>
          </div>
        </div>

        <div class="d-flex justify-space-between align-end mt-auto">
          <cart-product-item-delivery
            :item="item"
            :individualisation="individualisation"
          ></cart-product-item-delivery>

          <div class="text-h5 text-no-wrap pl-3">{{ price | price }}*</div>
        </div>
      </div>
    </div>

    <cart-product-item-individualisation
      v-if="individualisation"
      :individualisation="individualisation"
      class="mt-5"
    ></cart-product-item-individualisation>
  </div>
</template>

<script>
import {
  getCartItemCoverUrl,
  isLineItemBsFreeProductTypPseudo,
  joinCartItemPayloadOptions,
} from '~/helpers'
import CartProductItemImage from '~/components/cart/CartProductItemImage'
import CartProductItemDelivery from '~/components/cart/CartProductItemDelivery'
import CartProductItemIndividualisation from '~/components/cart/CartProductItemIndividualisation'

export default {
  name: 'CheckoutProductItem',
  components: {
    CartProductItemIndividualisation,
    CartProductItemDelivery,
    CartProductItemImage,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    individualisation: {
      type: Object,
      default: null,
    },
  },
  computed: {
    price() {
      return (
        this.item.price.totalPrice +
        (this.individualisation ? this.individualisation.price.totalPrice : 0)
      )
    },
    imageUrl() {
      return getCartItemCoverUrl(this.item)
    },
    title() {
      return this.item.label
    },
    url() {
      return isLineItemBsFreeProductTypPseudo(this.item)
        ? null
        : `/detail/${this.item.referencedId}`
    },
    deliveryColor() {
      return [
        this.individualisation ? 'pink-berry--text' : 'green-smaragd--text',
      ]
    },
    optionName() {
      return joinCartItemPayloadOptions(this.item)
    },
  },
}
</script>
