<template>
  <div>
    <checkout-product-item
      v-for="item in cartProductItems"
      :key="item.id"
      class="mb-10"
      :item="item"
      :class="[individualisationItems[item.id] ? 'mb-sm-12' : '']"
      :individualisation="individualisationItems[item.id]"
    ></checkout-product-item>
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api'
import { useCart } from '~/composables'
import CheckoutProductItem from '~/components/checkout/product/CheckoutProductItem'
import { getLineItemProductIndividualisations } from '~/helpers'

export default {
  name: 'CheckoutProductList',
  components: { CheckoutProductItem },
  setup(_, { root }) {
    const { cartItems, cartProductItems } = useCart(root)

    return {
      cartItems,
      cartProductItems,
      individualisationItems: computed(() =>
        getLineItemProductIndividualisations(cartItems.value)
      ),
    }
  },
}
</script>
