<template>
  <div>
    <cart-info-card hide-button></cart-info-card>

    <h3
      class="mt-5 d-flex align-center justify-center text-h4 beige-pastel-light"
      style="height: 50px"
    >
      {{ $t('commons.cart') }}
      <span class="text-body-4 pl-3">{{
        $t('checkout.stepOverview.numberArticle', { count })
      }}</span>
    </h3>

    <checkout-product-list class="mt-5"></checkout-product-list>

    <base-button
      large
      dynamic
      outlined
      class="mt-5 font-weight-bold mx-auto"
      rounded
      :to="localePath('/cart')"
      @click.native="$emit('click:wk')"
      >{{ $t('commons.editCart') }}</base-button
    >
  </div>
</template>

<script>
import { useCart } from '~/composables'
import CartInfoCard from '~/components/cart/CartInfoCard'
import CheckoutProductList from '~/components/checkout/product/CheckoutProductList'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'CheckoutSidebar',
  components: {
    BaseButton,
    CheckoutProductList,
    CartInfoCard,
  },
  setup(_, { root }) {
    const { count } = useCart(root)
    return {
      count,
    }
  },
}
</script>
