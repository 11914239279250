<template>
  <div :class="classes">
    <product-detail-free-shipping-card
      v-if="!hideFreeShipping"
      class="mb-7"
      text-class="text-body-3 text-md-body-2"
    ></product-detail-free-shipping-card>

    <div class="text-body-3">
      <div class="d-flex justify-space-between">
        <div>{{ $t('commons.subtotal') }}:</div>
        <div class="text-right">{{ productTotalPrice | price }}*</div>
      </div>
      <div v-if="totalDiscount < 0" class="d-flex justify-space-between mt-3">
        <div>{{ $t('commons.discount') }}:</div>
        <div class="text-right">{{ totalDiscount | price }}*</div>
      </div>
      <div class="d-flex justify-space-between mt-3">
        <div>{{ $t('commons.shippingCosts') }}:</div>
        <div class="text-right">{{ shippingTotal | price }}*</div>
      </div>
      <div class="d-flex justify-space-between text-h5 mt-3">
        <div>{{ $t('commons.total') }}:</div>
        <div class="text-right">{{ totalPrice | price }}*</div>
      </div>
    </div>

    <cart-submit-promotion
      v-if="!hidePromotionSubmit"
      v-show="!minimum"
      class="mt-5"
      background-color="white"
      @focus="$emit('focus:submit-promotion')"
    ></cart-submit-promotion>
    <div v-show="!minimum" class="mt-2">
      <cart-promotion-item
        v-for="promotion in appliedPromotionCodes"
        :key="promotion.id"
        class="px-3"
        :item="promotion"
        color="white"
      ></cart-promotion-item>
    </div>

    <slot name="action" :minimum="minimum">
      <base-button
        v-show="!minimum && !hideButton"
        large
        block
        class="mt-5 pink-berry white--text font-weight-bold"
        rounded
        :to="localePath('/checkout')"
        @click.native="$emit('click:checkout')"
        >{{ $t('actions.goToCheckout') }}</base-button
      >
      <payment-paypal-express-checkout-button
        v-if="!minimum && !hideButton"
        style="max-width: 150px"
        class="mt-3 ml-auto"
      ></payment-paypal-express-checkout-button>
    </slot>

    <div
      :class="[minimum ? 'd-none' : 'd-flex']"
      class="pa-5 justify-space-around"
    >
      <div
        v-if="$i18n.locale === 'fr-FR'"
        class="d-flex flex-column text-body-4 text-center"
      >
        <base-icon mdi="credit-cart" x-large></base-icon>
        {{ $t('commons.creditCardPayment') }}
      </div>
      <div v-else class="d-flex flex-column text-body-4 text-center">
        <base-icon mdi="invoice" x-large></base-icon>
        {{ $t('commons.purchaseInvoice') }}
      </div>
      <div class="d-flex flex-column text-body-4 text-center">
        <base-icon mdi="withdrawal" x-large></base-icon>
        {{ $t('commons.withdrawal') }}
      </div>
    </div>
  </div>
</template>

<script>
import { useCart } from '~/composables'
import ProductDetailFreeShippingCard from '~/components/product/detail/ProductDetailFreeShippingCard'
import CartSubmitPromotion from '~/components/cart/CartSubmitPromotion'
import CartPromotionItem from '~/components/cart/CartPromotionItem'
import PaymentPaypalExpressCheckoutButton from '~/components/payment/paypal/PaymentPaypalExpressCheckoutButton'
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'CartInfoCard',
  components: {
    BaseButton,
    BaseIcon,
    PaymentPaypalExpressCheckoutButton,
    CartPromotionItem,
    CartSubmitPromotion,
    ProductDetailFreeShippingCard,
  },
  props: {
    minimum: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    hideFreeShipping: {
      type: Boolean,
      default: false,
    },
    hidePromotionSubmit: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root }) {
    const {
      totalPrice,
      shippingTotal,
      appliedPromotionCodes,
      productTotalPrice,
    } = useCart(root)

    return {
      totalPrice,
      shippingTotal,
      appliedPromotionCodes,
      productTotalPrice,
    }
  },
  computed: {
    classes() {
      return {
        'grey-lighten-4': !this.minimum,
        'pa-3': !this.minimum,
      }
    },
    totalDiscount() {
      return this.appliedPromotionCodes.reduce((acc, cur) => {
        acc += cur.price.totalPrice

        return acc
      }, 0)
    },
  },
}
</script>
